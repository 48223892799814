<template>
  <div class="row">
    <div class="col col-4">
      <b-form-group>
        <label>Desconto</label>
        <b-form-input
          v-if="form.format === '%'"
          type="number"
          v-model="form.discount"
          :class="{ 'red-border': parseFloat(form.discount) < 0}"
        />
        <money
          v-else
          class="form-control money"
          v-model="form.discount"
          :class="{ 'red-border': parseFloat(form.discount) < 0}"
        />
        <div v-if="parseFloat(form.discount) < 0" class="custom-invalid-feedback">O valor deve ser positivo</div>
      </b-form-group>
    </div>
    <div class="col col-4">
      <b-form-group>
        <label>Acréscimo</label>
        <b-form-input
          v-if="form.format === '%'"
          type="number"
          v-model="form.addition"
          :class="{ 'red-border': parseFloat(form.addition) < 0}"
        />
        <money
          v-else
          class="form-control money"
          v-model="form.addition"
          :class="{ 'red-border': parseFloat(form.addition) < 0}"
        />
        <div v-if="parseFloat(form.addition) < 0" class="custom-invalid-feedback">O valor deve ser positivo</div>
      </b-form-group>
    </div>
    <div class="col col-4">
      <b-form-group>
        <label>$ ou %</label>
        <multiselect
          v-model="form.format"
          class="with-border"
          :options="['$', '%']"
          :showLabels="false"
          :internalSearch="false"
          :searchable="false"
          :allow-empty="false"
        >
          <template slot="caret">
            <div class="chevron">
              <ChevronDown />
            </div>
          </template>
          <template slot="noOptions">
            Nenhuma opção
          </template>
          <template slot="noResult">
            Nenhum resultado
          </template>
        </multiselect>
      </b-form-group>
    </div>
  </div>
</template>

<script>
  export default {
    components: { 
      ChevronDown: () => import('@/assets/icons/chevron-down.svg')
    },
    props: {
      billItems: Array,
    },
    data() {
      return {
        form: {
          discount: 0,
          addition: 0,
          format: '$',
        }
      }
    },
    methods: {
      calculateTotalValue() {
        this.billItems.forEach(billItem => {
          let total = 0;
          if (this.form.format === '$') {
            total = billItem.value + (-Math.abs(this.form.discount) + Math.abs(this.form.addition))
          } else if (this.form.format === '%') {
            const target = -Math.abs(this.form.discount) + Math.abs(this.form.addition)
            total = billItem.value + (billItem.value * target) / 100
          }

          billItem.discount = this.form.discount
          billItem.addition = this.form.addition
          billItem.format = this.form.format
          billItem.final_value = total
        })
      }
    },
    watch: {
      'form.value': function() { this.calculateTotalValue() },
      'form.discount': function() { this.calculateTotalValue() },
      'form.addition': function() { this.calculateTotalValue() },
      'form.format': function() { this.calculateTotalValue() },
    }
  }
</script>
<style lang="scss" scoped>
  .red-border{
    border: 0.7px red solid !important;
    color: red !important;
  }
</style>
  